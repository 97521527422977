<template>
  <svg role="img" :aria-label="props.label" :class="props.class || ''">
    <use :href="iconHref" />
  </svg>
</template>

<script setup lang="ts">
type Props = {
  name: string
  class?: string
  label?: string
  path?: string
  [key: string]: any
}

const props = withDefaults(defineProps<Props>(), {
  label: 'Svg Icon',
})

const iconHref = computed(
  () => `${props.path || '/svgs/common.svg'}#${props.name}`,
)
</script>
